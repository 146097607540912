import * as React from 'react';
import Opp3 from './Opp3';
import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme({
  palette: {
    primary: {
      main: "#049dfb",
      contrastText:"#fff"
    },
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}> <Opp3/>
    </ThemeProvider>
  );
}