import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { margin } from '@mui/system';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://klarway.com/">
                klarway.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
const _lang = "es"
const words = {
    es: {
        downloads: "Descargas",
        recomended: "Recomendado para este dispositivo",
        download: "Descargar"
    },
    en: {
        downloads: "Downloads",
        recomended: "Recommended for this device",
        download: "Download"

    }
}
// const os = navigator.userAgentData.platform
const tiers = [
    {
        title: 'Windows',
        buttonVariant: 'outlined',
    },
    {
        title: 'Mac Intel',
        buttonVariant: 'contained',
    },
    {
        title: 'Mac Silicon',
        buttonVariant: 'outlined',
    },
];
// switch (os) {
//     case "Windows":
//         tiers[0].subheader = words[_lang].recomended
//         break;
//     case "macOS":
//         tiers[1].subheader = words[_lang].recomended

//         break;

//     default:
//         break;
// }
const footers = [
    {
        title: 'Klarway',
        description: ['Team', 'History', 'Contact us', 'Locations'],
    },
    {
        title: 'Features',
        description: [
            'Cool stuff',
            'Random feature',
            'Team feature',
            'Developer stuff',
            'Another one',
        ],
    },
    {
        title: 'Resources',
        description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
    },
    {
        title: 'Legal',
        description: ['Privacy policy', 'Terms of use'],
    },
];

function PricingContent() {
    const [defaultOS, setOs] = React.useState(null)
    const [enviromentKW, setEnv] = React.useState('release')
    const getOS = () => {
        switch (defaultOS) {
            case 1:
                return 'windows'
                break;
            case 2:
                return 'mseries'
                break;
            case 3:
                return 'macos'

                break;

            default:
                break;
        }
    }
    const getUserAgentOS = () => {
        var userAgent = navigator.userAgent;

        if (userAgent.indexOf("Windows") != -1) {
            console.log("El usuario está utilizando Windows.");
            setOs(1)
        }

        // if (userAgent.indexOf("Macintosh") != -1 && userAgent.indexOf("Intel") != -1) {
        //     console.log("El usuario está utilizando un Mac con procesador Intel.");
        //     setOs(3)
        // }

        // // Comprobar si el sistema operativo es Mac con procesador M series
        // if (userAgent.indexOf("Macintosh") != -1 && userAgent.indexOf("Intel") == -1) {
        //     console.log("El usuario está utilizando un Mac con procesador M series.");
        //     setOs(2)
        // }
        if (userAgent.indexOf("Macintosh") != -1) {
            try {
                const getos = async () => {
                    const data = await navigator.userAgentData.getHighEntropyValues(['architecture'])
                    if (data.architecture) {
                        if (data.architecture === "arm") {
                            setOs(2)
                        }
                        setOs(3)

                    }
                    else {
                        setOs(null)

                    }
                }
                getos()
            }
            catch (e) {
                setOs(null)

            }
        }

    }
    React.useEffect(() => {

        let envpath = window.location.pathname.split('/').join('')
        if (!envpath) {
            envpath = 'release'
        }
        setEnv(envpath)


        //getUserAgentOS()

    }, [])

    return (
        <React.Fragment>
            <Grid display={'flex'} alignItems={'center'} >
                <Grid align={'center'} height={'100%'} width={'50%'} >
                    <Grid>
                        <Button disableRipple disableFocusRipple disabled>
                            <img src={process.env.PUBLIC_URL + '/kgris.svg'} width={"50%"} height={"70%"} style={{ margin: '10px' }} />
                        </Button>
                        <Typography variant="h5" align="center" color="text.secondary" component="p">
                            Selecciona tu sistema <br /> operativo e instala Klarway
                        </Typography>
                    </Grid>
                    <Grid display={'grid'} align={'center'} paddingTop={5}>
                        <Button onClick={() => { setOs(1) }} style={{ border: (defaultOS == 1 ? 'solid 2px' : 'solid 1px'), borderColor: (defaultOS == 1 ? '#049dfb' : "#c8c8c8"), color: (defaultOS == 1 ? '#049dfb' : "#000000"), height: '70%', width: '50%', margin: 'auto' }}><img src={ defaultOS == 1 ? process.env.PUBLIC_URL + '/winlogo1.png' : process.env.PUBLIC_URL + '/winlogo.png' } width={"5%"} style={{ margin: '10px' }} /> <h3>Windows</h3></Button>
                        <Button onClick={() => { setOs(3) }} style={{ border: (defaultOS == 3 ? 'solid 2px' : 'solid 1px'), borderColor: (defaultOS == 3 ? '#049dfb' : "#c8c8c8"), color: (defaultOS == 3 ? '#049dfb' : "#000000"), height: '70%', width: '50%', margin: 'auto' }}><img src={ defaultOS == 3 ? process.env.PUBLIC_URL + '/ioslogo1.png' : process.env.PUBLIC_URL + '/ioslogo.png' } width={"5%"} style={{ margin: '10px' }} /> <h3>Mac Chip Intel</h3></Button>
                        <Button onClick={() => { setOs(2) }} style={{ border: (defaultOS == 2 ? 'solid 2px' : 'solid 1px'), borderColor: (defaultOS == 2 ? '#049dfb' : "#c8c8c8"), color: (defaultOS == 2 ? '#049dfb' : "#000000"), height: '70%', width: '50%', margin: 'auto' }}><img src={ defaultOS == 2 ? process.env.PUBLIC_URL + '/ioslogo1.png' : process.env.PUBLIC_URL + '/ioslogo.png' } width={"5%"} style={{ margin: '10px' }} /> <h3>Mac Chip Apple</h3></Button>
                        <Button onClick={() => {
                            window.open('https://bivproctor.work/downloads/' + getOS() + '/' + enviromentKW)
                        }} disabled={!defaultOS}
                        variant='contained'
                         style={{
                            // borderColor: '#049dfb',
                            // color: '#fff',
                            // backgroundColor: "#049dfb",
                             height: '70%', width: '50%', margin: 'auto'
                        }}><h3>DESCARGAR</h3></Button>
                    </Grid>
                </Grid>
                <Grid
                    width={'50%'}
                    style={{
                    height: '100vh',
                    background: `url('${process.env.PUBLIC_URL + '/pcinicio1.png'}')`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}>
                    {/* <img src={process.env.PUBLIC_URL + '/pcinicio.png'} style={{width:'100%', maxHeight:'100%'}} /> */}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default function Opp3() {
    return <PricingContent />;
}